import "./App.scss"
import { Outlet } from 'react-router-dom';
import { NavBar } from "./components/navbar/navbar";
import { useState } from "react";
import { RulePathNames } from "./enums/rulePathNames";

export const App = () => {
  const [selectedRule, setSelectedRule] = useState<RulePathNames>(RulePathNames.RulesFo)

  return (
    <div className="h-100" style={{ minHeight: '0px', overflow: 'hidden' }}>
      <NavBar />
      <Outlet context={{ selectedRule, setSelectedRule }} />
    </div>
  );
}


